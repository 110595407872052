<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="Internal Ignition Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Internal Ignition Test Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/InternalIgnitionDetailed-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/InternalIgnitionDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of the Internal Ignition test is to determine whether
              igniting a substance under confinement will result in a
              deflagration with explosive violence. Applicable to
              non-homogeneous substances.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              The Internal Ignition test is used in both UN Series Test 1 (c)
              and Test 2 (c). In UN Test 1 (c) a 20-gram black powder igniter is
              used whereas in UN Test 2 (c) a 10-gram black powder igniter is
              used.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div>
                <table class="test-table">
                  <tbody>
                    <tr>
                      <th>Key Parameter</th>
                      <th>Objectives</th>
                      <th>Origin</th>
                      <th>Specs</th>
                    </tr>
                    <tr>
                      <td>Confining medium (steel pipe with end caps)</td>
                      <td>
                        Confined vessel that can be used as evidence of sample
                        reactivity should the material be capable of sustaining
                        the reaction
                      </td>
                      <td></td>
                      <td>
                        3-inch Schedule 80 carbon steel (A53 Grade B) pipe, 74mm
                        ID, 7.6mm wall, 45.7 cm (18-in) long, capped at both
                        ends with 3,000-pound forged steel end caps
                      </td>
                    </tr>
                    <tr>
                      <td>Ignition stimulus (igniter size and type)</td>
                      <td>
                        Ensure a sufficient amount of the material is ignited
                      </td>
                      <td></td>
                      <td>
                        20-gram black powder igniter with lead wires sealed
                      </td>
                    </tr>
                    <tr>
                      <td>Ignition position</td>
                      <td>
                        Provide an ignition in the center of the confined
                        substance
                      </td>
                      <td></td>
                      <td>Center of the sample</td>
                    </tr>
                    <tr>
                      <td>Substance density</td>
                      <td>
                        Determine whether the substance in its in-process form
                        has explosive properties
                      </td>
                      <td></td>
                      <td>
                        Substance to be tested as near as possible to the
                        in-process density
                      </td>
                    </tr>
                    <tr>
                      <td>Pipe position</td>
                      <td>
                        Allow the weight of the substance to provide additional
                        confinement
                      </td>
                      <td></td>
                      <td>The tube is fired in a vertical position</td>
                    </tr>
                    <tr>
                      <td>Number of trials</td>
                      <td>Obtain an accurate characterization of the sample</td>
                      <td></td>
                      <td>3 trials</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Damage to the steel pipe</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Fragmentation of pipe into at least two distinct pieces
                      (pipe rupture by splitting open is not a failure): Class 1
                    </td>
                  </tr>
                  <tr>
                    <td>Damage to the end cap</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Fragmentation of either cap into at least two distinct
                      pieces: Class 1
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">*OR relationship</p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent class="pt-8" title="Setup Example" size="medium" />
            <a
              :href="
                require('../../assets/IPSeries/setup-internalignition-min.jpg')
              "
              target="_blank"
            >
              <img
                style="width:80%;"
                class="m-auto"
                src="@/assets/IPSeries/setup-internalignition-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Pipe and Caps Not Fragmented (-)"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/no-go-ignition-min.jpg')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/no-go-ignition-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Video of Pipe and Caps Not Fragmented (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166131730?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166131730"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Video of Fragmented Pipe and Caps (+)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166131731?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166131731"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Video of Pipe Splitting Open After Test (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166206225?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166206225"
              allow="autoplay; fullscreen"
              id="player_3"
              name="fitvid2"
            ></iframe>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
